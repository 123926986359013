'use strict'

function bootstrapMessage(bootstrapArguments, fetchScriptsCount) {
    return {
        type: 'wix_code_worker_bootstrap',
        bootstrapArguments,
        fetchScriptsCount
    }
}

function loadUserCodeMessage(workerId, wixCode) {
    return {
        type: 'wix_code_worker_load_user_code',
        workerId,
        wixCode
    }
}

function loadMessage(workerId, elementoryArguments, wixCode, applications, sdkParameters, routersMap, storage, rgi) {
    return {
        type: 'wix_code_worker_load',
        workerId,
        elementoryArguments,
        wixCode,
        applications,
        sdkParameters,
        routersMap,
        storage,
        rgi
    }
}

function initMessage(id, apps) {
    return {
        type: 'wix_code_worker_init',
        id,
        apps
    }
}

function startMessage(context, id) {
    return {
        type: 'wix_code_worker_start',
        context,
        id
    }
}

function storageWasUpdatedMessage(storageValue) {
    return {
        type: 'storageWasUpdated',
        storageValue
    }
}

function updateWixCodeModelDataAfterLoginMessage(workerId, elementoryArguments) {
    return {
        type: 'update_wix_code_model_data_after_login',
        workerId,
        elementoryArguments
    }
}

function scriptImportMessage(url, script) {
    return {
        type: 'script_import_message',
        url,
        script
    }
}

function stopMessage(contextId) {
    return {
        contextId,
        type: 'stop'
    }
}

function isStopMessage(message) {
    return message.type === 'stop'
}

module.exports = {
    bootstrapMessage,
    loadUserCodeMessage,
    loadMessage,
    initMessage,
    startMessage,
    storageWasUpdatedMessage,
    updateWixCodeModelDataAfterLoginMessage,
    scriptImportMessage,
    stopMessage,
    isStopMessage
}
