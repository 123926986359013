'use strict'

module.exports = {
    APPS: {
        SANTA_MEMBERS: {
            appDefId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'
        },
        FORM_BUILDER: {
            appDefId: '14ce1214-b278-a7e4-1373-00cebd1bef7c'
        }
    },
    WIX_CODE_NAMESPACES_AND_ELEMENTORY_SUPPORT_PATH: '/wixCodeNamespacesAndElementorySupport.min.js',
    DATA_BINDING: 'dataBinding',
    REMOTE_DOM_URL: '//unpkg.parastorage.com/@shimil/remote-dom@3.0.3/dist/remote.min.js',
    SEMI_NATIVE_SDK_URL: 'http://static.parastorage.com/services/semi-native-sdk/1.3.0/lib/wix.min.js',
    PM_RPC_INTENTS: {
        INVOKE: 'invoke',
        RPC_RESOLVE: 'rpc-resolve',
        RPC_REJECT: 'rpc-reject',
        API_DESCRIPTION: 'api-desc',
        INVOKE_FUNCTION: 'invoke-func',
        RESOLVE: 'resolve',
        REJECT: 'reject',
        REQUEST_API: 'request-api'
    },
    WIX_CODE: {
        MESSAGE_INTENT: {
            WIX_CODE_MESSAGE: 'WIX_CODE',
            WIX_CODE_SITE_API: 'WIX_CODE_SITE_API',
            WIX_CODE_APP_API: 'WIX_CODE_APP_API',
            SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE: 'SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE',
            SANTA_APPLICATION_CHANNEL_MESSAGE: 'SANTA_APPLICATION_CHANNEL_MESSAGE'
        },
        MESSAGE_TYPE: {
            CONSOLE: 'console',
            IFRAME_COMMAND: 'wix_code_iframe_command',
            GOOGLE_ANALYTICS: 'googleAnalytics',
            UPDATE_WIX_CODE_MODEL_DATA_AFTER_LOGIN: 'update_wix_code_model_data_after_login',
            REQUEST_API: 'REQUEST_API',
            PERFORMANCE_METRICS_READY: 'performance_metrics_ready',
            PLATFORM_PUBLIC_API_PREFIX: 'viewer_platform_public_api_',
            OPEN_MESSAGE_CHANNEL: 'openMessageChannel',
            SANTA_READY: 'santaReady',
            SANTA_PAGE_CHANGE: 'santaPageChange'
        }
    }
}
