'use strict'

const _ = require('lodash')
const FallbackStorage = require('./FallbackStorage')

const prefix = 'platform_app_'
function getScopedValues(storage) {
    const scopedValues = {}
    const filteredKeys = Object.keys(storage).filter(keyName => typeof keyName === 'string' && _.startsWith(keyName, prefix))
    for (const keyName of filteredKeys) { //eslint-disable-line no-restricted-syntax
        scopedValues[keyName.replace(prefix, '')] = storage.getItem(keyName)
    }
    return scopedValues
}

module.exports = {
    get: (storageEnabled = false) => {
        const storageObject = storageEnabled ?
            window :
            {localStorage: new FallbackStorage(), sessionStorage: new FallbackStorage()}
        return {
            serialize() {
                return JSON.stringify({
                    local: getScopedValues(storageObject.localStorage),
                    session: getScopedValues(storageObject.sessionStorage)
                })
            },
            setItem(type, key, value) {
                storageObject[type].setItem(prefix + key, value)
            }
        }
    }
}